import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PortableText from '../portableText'

export default function ClassSchedule() {
  const data = useStaticQuery(graphql`
    {
      allSanityPricing(sort: { order: ASC, fields: priority }) {
        edges {
          node {
            title
            priority
            _rawSubtitle(resolveReferences: { maxDepth: 5 })
            _rawBody(resolveReferences: { maxDepth: 5 })
          }
        }
      }
    }
  `)
  const allSanityPricing = (data || {}).allSanityPricing
  console.log('EDGES', allSanityPricing.edges)

  return (
    <div className="container justify-content-center px-0">
      {allSanityPricing.edges
        .filter((pricing) => pricing.node.priority !== null)
        .map(({ node }) => (
          <div className="col-12 pt-6 px-0" key={node.id}>
            <h1 className="text-pink text-center">{node.title}</h1>
            <h3 className="text-gray text-center">
              <PortableText blocks={node._rawSubtitle} />
            </h3>
            <div className="text-gray text-center">
              <PortableText blocks={node._rawBody} />
            </div>
          </div>
        ))}
      <div className="col-12 pt-6 px-0">
        <p className="lead text-pink text-center">
          Mats and props are provided but please feel free to bring your own.
        </p>
        <p className="lead text-pink text-center">
          Payment to be made via bank transfer in advance.
        </p>
      </div>
    </div>
  )
}

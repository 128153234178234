import React from 'react'
import emergence from 'emergence.js'
import Header from 'components/Header'
import Footer from 'components/Footer'
import { siteMetadata } from '../../../gatsby-config'
import AOS from 'aos'

import 'modern-normalize/modern-normalize.css'
import 'prismjs/themes/prism.css'
import 'scss/gatstrap.scss'
import 'animate.css/animate.css'
import 'font-awesome/css/font-awesome.css'
import '../../../node_modules/aos/dist/aos.css'
//import 'swiper/dist/css/swiper.css'

import '../../assets/css/vendor.css'
import '../../assets/css/style.css'
import './style.scss'

import { globalAgent } from 'https'

var $ = require('jquery')
global.jQuery = $

require('owl.carousel')
require('magnific-popup')
global.Masonry = require('../../../node_modules/masonry-layout/dist/masonry.pkgd.js')
require('imagesloaded')
global.skrollr = require('skrollr')
global.Vivus = require('vivus')
require('video.js')
require('videojs-youtube')
require('lavalamp')
require('jquery-background-video')
global.Headroom = require('headroom.js')
require('jquery.countdown')
require('jquery-circle-progress')
require('typed.js')
global.SmoothScroll = require('smooth-scroll')
require('filterizr')

require('../../assets/js/vendor/jquery.selectric.js')
require('../../assets/js/vendor/jquery.accordion.js')
require('../../assets/js/vendor/prism.js')
require('../../assets/js/vendor/modernizr.js')

require('../../assets/js/vendor.js')
require('../../assets/js/app.js')

class Layout extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    emergence.init()

    if (typeof window !== `undefined`) {
      $.fn.Launch()
      AOS.init()
    }
  }

  componentDidUpdate() {
    emergence.init()

    //if (typeof window !== `undefined`) {
    //  $.fn.Launch();
    //  AOS.refresh();
    //}
  }

  render() {
    const { children } = this.props
    return (
      <div id="ywa-layout">
        <Header />
        {children}
        <Footer />
      </div>
    )
  }
}

export default Layout

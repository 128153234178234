import React from 'react'
import Layout from '../components/Layout/index'
import Prices from '../components/Prices/prices'
import LayoutWhite from 'components/LayoutWhite/index'

export default function pricing() {
  return (
    <LayoutWhite>
      <Prices />
    </LayoutWhite>
  )
}
